import React from "react";
import styled from "styled-components";

import Presenter from "legacy-js/react/views/Player/Presenter";
import { RemoveSplashScreen } from "legacy-js/editor/SplashScreen";
import { MeetingRoom } from "legacy-js/react/views/Player/MeetingRoom";

const Container = styled.div`
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    position: relative;
`;

class MeetingRoomWrapper extends React.Component {
    componentDidMount() {
        RemoveSplashScreen(true);
    }

    render() {
        return <Container><MeetingRoom/></Container>;
    }
}

export default MeetingRoomWrapper;
